// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SearchPanel_container__yJDlU{\r\n    width: 100%;\r\n    display: flex;\r\n    flex-direction: column;\r\n    justify-content: center;\r\n}\r\n\r\n.SearchPanel_options__1KanI > *{\r\n    color: #999999;\r\n    font-weight: lighter;\r\n    font-style: oblique;\r\n}\r\n\r\n", "",{"version":3,"sources":["webpack://./src/components/Main/InfoRight/SearchPanel/SearchPanel.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,uBAAuB;AAC3B;;AAEA;IACI,cAAc;IACd,oBAAoB;IACpB,mBAAmB;AACvB","sourcesContent":[".container{\r\n    width: 100%;\r\n    display: flex;\r\n    flex-direction: column;\r\n    justify-content: center;\r\n}\r\n\r\n.options > *{\r\n    color: #999999;\r\n    font-weight: lighter;\r\n    font-style: oblique;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "SearchPanel_container__yJDlU",
	"options": "SearchPanel_options__1KanI"
};
export default ___CSS_LOADER_EXPORT___;
