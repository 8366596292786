// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Home_container__MGYYT{\r\n    width: 25vw;\r\n}\r\n.Home_header__1Nkvr{\r\n    height: 8vh;\r\n    position: relative;\r\n    top: 1.2em;\r\n}\r\n.Home_header_info__tr7V6{\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    position: absolute;\r\n    bottom: 1em;\r\n    left: 1em;\r\n}\r\n.Home_header_info__tr7V6 > * {\r\n    margin-right: 2em;\r\n}\r\n.Home_options__de7ba{\r\n    height: 100%;\r\n}\r\n.Home_avatar__Cd8D5{\r\n    cursor: pointer;\r\n}\r\n.Home_avatar__Cd8D5:hover{\r\n    opacity: 50%;\r\n}\r\n.Home_username__iFVeR{\r\n    font-size: 2em;\r\n    font-weight: bold;\r\n    color: #666666;\r\n    font-style: oblique;\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n}\r\n", "",{"version":3,"sources":["webpack://./src/components/Home/Home.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;AACf;AACA;IACI,WAAW;IACX,kBAAkB;IAClB,UAAU;AACd;AACA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,kBAAkB;IAClB,WAAW;IACX,SAAS;AACb;AACA;IACI,iBAAiB;AACrB;AACA;IACI,YAAY;AAChB;AACA;IACI,eAAe;AACnB;AACA;IACI,YAAY;AAChB;AACA;IACI,cAAc;IACd,iBAAiB;IACjB,cAAc;IACd,mBAAmB;IACnB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB","sourcesContent":[".container{\r\n    width: 25vw;\r\n}\r\n.header{\r\n    height: 8vh;\r\n    position: relative;\r\n    top: 1.2em;\r\n}\r\n.header_info{\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    position: absolute;\r\n    bottom: 1em;\r\n    left: 1em;\r\n}\r\n.header_info > * {\r\n    margin-right: 2em;\r\n}\r\n.options{\r\n    height: 100%;\r\n}\r\n.avatar{\r\n    cursor: pointer;\r\n}\r\n.avatar:hover{\r\n    opacity: 50%;\r\n}\r\n.username{\r\n    font-size: 2em;\r\n    font-weight: bold;\r\n    color: #666666;\r\n    font-style: oblique;\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Home_container__MGYYT",
	"header": "Home_header__1Nkvr",
	"header_info": "Home_header_info__tr7V6",
	"options": "Home_options__de7ba",
	"avatar": "Home_avatar__Cd8D5",
	"username": "Home_username__iFVeR"
};
export default ___CSS_LOADER_EXPORT___;
