// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EnhancerPanel_placeholder__GYiOp{\r\n    color: #aaaaaa;\r\n    font-style: italic;\r\n    margin-left: 1vw;\r\n}\r\n\r\n.EnhancerPanel_add_card_wrapper__ufbxD{\r\n    margin-top: 0.5em;\r\n    margin-bottom: 1em;\r\n}\r\n.EnhancerPanel_add_card_wrapper__ufbxD > *{\r\n    margin-right: 2em;\r\n}\r\n.EnhancerPanel_divider__kFmfQ{\r\n    color: #999999;\r\n    font-weight: lighter;\r\n    font-size: 0.9em;\r\n    font-style: oblique;\r\n}\r\n.EnhancerPanel_option__857YB{\r\n    scale: 130%;\r\n}\r\n.EnhancerPanel_groups__BMemN{\r\n\r\n}", "",{"version":3,"sources":["webpack://./src/components/Main/InfoRight/EnhancerPanel/EnhancerPanel.module.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,iBAAiB;IACjB,kBAAkB;AACtB;AACA;IACI,iBAAiB;AACrB;AACA;IACI,cAAc;IACd,oBAAoB;IACpB,gBAAgB;IAChB,mBAAmB;AACvB;AACA;IACI,WAAW;AACf;AACA;;AAEA","sourcesContent":[".placeholder{\r\n    color: #aaaaaa;\r\n    font-style: italic;\r\n    margin-left: 1vw;\r\n}\r\n\r\n.add_card_wrapper{\r\n    margin-top: 0.5em;\r\n    margin-bottom: 1em;\r\n}\r\n.add_card_wrapper > *{\r\n    margin-right: 2em;\r\n}\r\n.divider{\r\n    color: #999999;\r\n    font-weight: lighter;\r\n    font-size: 0.9em;\r\n    font-style: oblique;\r\n}\r\n.option{\r\n    scale: 130%;\r\n}\r\n.groups{\r\n\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"placeholder": "EnhancerPanel_placeholder__GYiOp",
	"add_card_wrapper": "EnhancerPanel_add_card_wrapper__ufbxD",
	"divider": "EnhancerPanel_divider__kFmfQ",
	"option": "EnhancerPanel_option__857YB",
	"groups": "EnhancerPanel_groups__BMemN"
};
export default ___CSS_LOADER_EXPORT___;
