// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HeuristicExamMain_placeHolder__qL4W5{\r\n    min-height: 20vh;\r\n}\r\n.HeuristicExamMain_basic_info__6Xwxp{\r\n    color: #666666;\r\n    font-style: oblique;\r\n}\r\n.HeuristicExamMain_quiz_title__OF1Ix{\r\n    font-size: 1.2em;\r\n    margin-left: 2em;\r\n}\r\n.HeuristicExamMain_timer__AV6-n{\r\n    color: #aaaaaa;\r\n}\r\n.HeuristicExamMain_up_and_down__ievg\\+{\r\n    width: 1em;\r\n    display: flex;\r\n    flex-direction: column;\r\n}\r\n.HeuristicExamMain_up_and_down__ievg\\+ > *{\r\n    margin-bottom: 0.5em;\r\n    position: relative;\r\n    top: -0.3em;\r\n}", "",{"version":3,"sources":["webpack://./src/components/Main/InfoRight/AnalysisPanel/main/HeuristicExamMain/HeuristicExamMain.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;AACA;IACI,cAAc;IACd,mBAAmB;AACvB;AACA;IACI,gBAAgB;IAChB,gBAAgB;AACpB;AACA;IACI,cAAc;AAClB;AACA;IACI,UAAU;IACV,aAAa;IACb,sBAAsB;AAC1B;AACA;IACI,oBAAoB;IACpB,kBAAkB;IAClB,WAAW;AACf","sourcesContent":[".placeHolder{\r\n    min-height: 20vh;\r\n}\r\n.basic_info{\r\n    color: #666666;\r\n    font-style: oblique;\r\n}\r\n.quiz_title{\r\n    font-size: 1.2em;\r\n    margin-left: 2em;\r\n}\r\n.timer{\r\n    color: #aaaaaa;\r\n}\r\n.up_and_down{\r\n    width: 1em;\r\n    display: flex;\r\n    flex-direction: column;\r\n}\r\n.up_and_down > *{\r\n    margin-bottom: 0.5em;\r\n    position: relative;\r\n    top: -0.3em;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"placeHolder": "HeuristicExamMain_placeHolder__qL4W5",
	"basic_info": "HeuristicExamMain_basic_info__6Xwxp",
	"quiz_title": "HeuristicExamMain_quiz_title__OF1Ix",
	"timer": "HeuristicExamMain_timer__AV6-n",
	"up_and_down": "HeuristicExamMain_up_and_down__ievg+"
};
export default ___CSS_LOADER_EXPORT___;
