import React from 'react';

const PlainLoading = () => {
    return (
        <div>
            <span style={{color:"#999", fontStyle:"oblique"}}>
                加载中 . . .
            </span>
        </div>
    );
};

export default PlainLoading;