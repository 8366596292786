// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ReviewPanel_next__uwinv {\r\n    display: flex;\r\n    \r\n}\r\n.ReviewPanel_next__uwinv > *{\r\n    color: #666666;\r\n    font-style: oblique;\r\n    font-size: 1.1em;\r\n    font-weight: lighter;\r\n    margin-right: 2em;\r\n    text-decoration: underline;\r\n}\r\n.ReviewPanel_next__uwinv > span:hover{\r\n    cursor: pointer;\r\n    color: #72aff6;\r\n}", "",{"version":3,"sources":["webpack://./src/components/Main/InfoRight/EnhancerPanel/ReviewPanel/ReviewPanel.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;;AAEjB;AACA;IACI,cAAc;IACd,mBAAmB;IACnB,gBAAgB;IAChB,oBAAoB;IACpB,iBAAiB;IACjB,0BAA0B;AAC9B;AACA;IACI,eAAe;IACf,cAAc;AAClB","sourcesContent":[".next {\r\n    display: flex;\r\n    \r\n}\r\n.next > *{\r\n    color: #666666;\r\n    font-style: oblique;\r\n    font-size: 1.1em;\r\n    font-weight: lighter;\r\n    margin-right: 2em;\r\n    text-decoration: underline;\r\n}\r\n.next > span:hover{\r\n    cursor: pointer;\r\n    color: #72aff6;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"next": "ReviewPanel_next__uwinv"
};
export default ___CSS_LOADER_EXPORT___;
