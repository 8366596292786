// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HotspotAnalysisCard_item__FpYGa{\r\n    margin-bottom: 0.2em;\r\n}\r\n.HotspotAnalysisCard_index__ZlpsF{\r\n    color: #666666;\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n}\r\n.HotspotAnalysisCard_time__zHKub{\r\n    font-size: 1.5em;\r\n    font-weight: lighter;\r\n    font-style: oblique;\r\n    color: #666666;\r\n    padding-left: 0.3em;\r\n}\r\n.HotspotAnalysisCard_title__K8\\+mW{\r\n    display: flex;\r\n}\r\n.HotspotAnalysisCard_title__K8\\+mW > *{\r\n    margin-right: 1em;\r\n}", "",{"version":3,"sources":["webpack://./src/components/Main/InfoRight/AnalysisPanel/analysis/HotspotAnalysisCard/HotspotAnalysisCard.module.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;AACxB;AACA;IACI,cAAc;IACd,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;AACA;IACI,gBAAgB;IAChB,oBAAoB;IACpB,mBAAmB;IACnB,cAAc;IACd,mBAAmB;AACvB;AACA;IACI,aAAa;AACjB;AACA;IACI,iBAAiB;AACrB","sourcesContent":[".item{\r\n    margin-bottom: 0.2em;\r\n}\r\n.index{\r\n    color: #666666;\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n}\r\n.time{\r\n    font-size: 1.5em;\r\n    font-weight: lighter;\r\n    font-style: oblique;\r\n    color: #666666;\r\n    padding-left: 0.3em;\r\n}\r\n.title{\r\n    display: flex;\r\n}\r\n.title > *{\r\n    margin-right: 1em;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": "HotspotAnalysisCard_item__FpYGa",
	"index": "HotspotAnalysisCard_index__ZlpsF",
	"time": "HotspotAnalysisCard_time__zHKub",
	"title": "HotspotAnalysisCard_title__K8+mW"
};
export default ___CSS_LOADER_EXPORT___;
