// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ClozePlayer_options__Cfl3h {\r\n    display: flex;\r\n    justify-content: space-evenly;\r\n}\r\n\r\n.ClozePlayer_left_options__3ve\\+H{\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n}\r\n.ClozePlayer_left_options__3ve\\+H > *{\r\n    margin-bottom: 3em;\r\n}\r\n\r\n.ClozePlayer_placeholder__Sjceo{\r\n    position: absolute;\r\n    color: #aaa;\r\n    font-style: oblique;\r\n}", "",{"version":3,"sources":["webpack://./src/components/Main/InfoRight/EnhancerPanel/resource/ClozePlayer/ClozePlayer.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,6BAA6B;AACjC;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;AACvB;AACA;IACI,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,mBAAmB;AACvB","sourcesContent":[".options {\r\n    display: flex;\r\n    justify-content: space-evenly;\r\n}\r\n\r\n.left_options{\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n}\r\n.left_options > *{\r\n    margin-bottom: 3em;\r\n}\r\n\r\n.placeholder{\r\n    position: absolute;\r\n    color: #aaa;\r\n    font-style: oblique;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"options": "ClozePlayer_options__Cfl3h",
	"left_options": "ClozePlayer_left_options__3ve+H",
	"placeholder": "ClozePlayer_placeholder__Sjceo"
};
export default ___CSS_LOADER_EXPORT___;
