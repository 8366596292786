// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FullCheckConfigs_title__ElqDw{\r\n    color: #666666;\r\n    font-weight: bold;\r\n    font-size: 1.2em;\r\n}\r\n.FullCheckConfigs_info__2hK1m{\r\n    font-weight: lighter;\r\n    font-size: 1.1em;\r\n    font-style: oblique;\r\n}", "",{"version":3,"sources":["webpack://./src/components/Main/InfoRight/AnalysisPanel/ExamConfigs/FullCheckConfigs/FullCheckConfigs.module.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,iBAAiB;IACjB,gBAAgB;AACpB;AACA;IACI,oBAAoB;IACpB,gBAAgB;IAChB,mBAAmB;AACvB","sourcesContent":[".title{\r\n    color: #666666;\r\n    font-weight: bold;\r\n    font-size: 1.2em;\r\n}\r\n.info{\r\n    font-weight: lighter;\r\n    font-size: 1.1em;\r\n    font-style: oblique;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": "FullCheckConfigs_title__ElqDw",
	"info": "FullCheckConfigs_info__2hK1m"
};
export default ___CSS_LOADER_EXPORT___;
