// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FullCheckExamMain_container__8TSx4{\r\n    min-height: 20vh;\r\n}\r\n.FullCheckExamMain_placeHolder__k2QKP{\r\n    min-height: 20vh;\r\n}\r\n.FullCheckExamMain_basic_info__GkivH{\r\n    color: #666666;\r\n    font-style: oblique;\r\n}\r\n.FullCheckExamMain_quiz_title__dxKvP{\r\n    font-size: 1.2em;\r\n    margin-left: 2em;\r\n}\r\n.FullCheckExamMain_timer__PsCZE{\r\n    color: #aaaaaa;\r\n}\r\n", "",{"version":3,"sources":["webpack://./src/components/Main/InfoRight/AnalysisPanel/main/FullCheckExamMain/FullCheckExamMain.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;AACA;IACI,gBAAgB;AACpB;AACA;IACI,cAAc;IACd,mBAAmB;AACvB;AACA;IACI,gBAAgB;IAChB,gBAAgB;AACpB;AACA;IACI,cAAc;AAClB","sourcesContent":[".container{\r\n    min-height: 20vh;\r\n}\r\n.placeHolder{\r\n    min-height: 20vh;\r\n}\r\n.basic_info{\r\n    color: #666666;\r\n    font-style: oblique;\r\n}\r\n.quiz_title{\r\n    font-size: 1.2em;\r\n    margin-left: 2em;\r\n}\r\n.timer{\r\n    color: #aaaaaa;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "FullCheckExamMain_container__8TSx4",
	"placeHolder": "FullCheckExamMain_placeHolder__k2QKP",
	"basic_info": "FullCheckExamMain_basic_info__GkivH",
	"quiz_title": "FullCheckExamMain_quiz_title__dxKvP",
	"timer": "FullCheckExamMain_timer__PsCZE"
};
export default ___CSS_LOADER_EXPORT___;
