// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LocalPanel_download_wrapper__1\\+R0V{\r\n    height: 10vh;\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n}\r\n.LocalPanel_prompt__wkoK-{\r\n    color: #666666;\r\n    font-size: 1.2em;\r\n    font-weight: bold;\r\n}\r\n.LocalPanel_tips__4EZFB{\r\n    color: #999999;\r\n    font-size: 0.9em;\r\n}", "",{"version":3,"sources":["webpack://./src/components/Main/InfoRight/LocalPanel/LocalPanel.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;AACA;IACI,cAAc;IACd,gBAAgB;IAChB,iBAAiB;AACrB;AACA;IACI,cAAc;IACd,gBAAgB;AACpB","sourcesContent":[".download_wrapper{\r\n    height: 10vh;\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n}\r\n.prompt{\r\n    color: #666666;\r\n    font-size: 1.2em;\r\n    font-weight: bold;\r\n}\r\n.tips{\r\n    color: #999999;\r\n    font-size: 0.9em;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"download_wrapper": "LocalPanel_download_wrapper__1+R0V",
	"prompt": "LocalPanel_prompt__wkoK-",
	"tips": "LocalPanel_tips__4EZFB"
};
export default ___CSS_LOADER_EXPORT___;
