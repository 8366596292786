// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RecentKnodeConfigs_options__TkZw1 > *{\r\n    margin-right: 0.3em;\r\n}", "",{"version":3,"sources":["webpack://./src/components/Main/InfoRight/AnalysisPanel/ExamConfigs/RecentKnodeConfigs/RecentKnodeConfigs.module.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;AACvB","sourcesContent":[".options > *{\r\n    margin-right: 0.3em;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"options": "RecentKnodeConfigs_options__TkZw1"
};
export default ___CSS_LOADER_EXPORT___;
