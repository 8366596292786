// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TitleAndOptions_option_wrapper__RzfXG{\r\n    height: 4em;\r\n    display: flex;\r\n    flex-direction: column;\r\n    justify-content: space-between;\r\n}", "",{"version":3,"sources":["webpack://./src/components/Main/InfoRight/AnalysisPanel/main/utils/TitleAndOptions.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,8BAA8B;AAClC","sourcesContent":[".option_wrapper{\r\n    height: 4em;\r\n    display: flex;\r\n    flex-direction: column;\r\n    justify-content: space-between;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"option_wrapper": "TitleAndOptions_option_wrapper__RzfXG"
};
export default ___CSS_LOADER_EXPORT___;
