// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AnalysisPanel_selected_panel_title__iDxc4{\r\n    font-size: 1.2em;\r\n    color: #666666;\r\n    font-weight: bold;\r\n}", "",{"version":3,"sources":["webpack://./src/components/Main/InfoRight/AnalysisPanel/AnalysisPanel.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,cAAc;IACd,iBAAiB;AACrB","sourcesContent":[".selected_panel_title{\r\n    font-size: 1.2em;\r\n    color: #666666;\r\n    font-weight: bold;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"selected_panel_title": "AnalysisPanel_selected_panel_title__iDxc4"
};
export default ___CSS_LOADER_EXPORT___;
