// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ChatPanel_main__0J6so{\r\n    min-height: 20em;\r\n}\r\n.ChatPanel_send__PZ--f{\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n}\r\n.ChatPanel_title__1y9yp{\r\n    font-style: oblique;\r\n    font-size: 1.3em;\r\n    color: #666666;\r\n    font-weight: bold;\r\n}\r\n.ChatPanel_markdown_wrapper__JWw8r{\r\n    background-color: #eee;\r\n    border-radius: 0.5em;\r\n    padding: 0.2em 0.5em;\r\n    opacity: 40%;\r\n}\r\n.ChatPanel_card__A8gR-{\r\n    color: #666666;\r\n}", "",{"version":3,"sources":["webpack://./src/components/Main/InfoRight/ChatPanel/ChatPanel.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;AACA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;AACA;IACI,mBAAmB;IACnB,gBAAgB;IAChB,cAAc;IACd,iBAAiB;AACrB;AACA;IACI,sBAAsB;IACtB,oBAAoB;IACpB,oBAAoB;IACpB,YAAY;AAChB;AACA;IACI,cAAc;AAClB","sourcesContent":[".main{\r\n    min-height: 20em;\r\n}\r\n.send{\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n}\r\n.title{\r\n    font-style: oblique;\r\n    font-size: 1.3em;\r\n    color: #666666;\r\n    font-weight: bold;\r\n}\r\n.markdown_wrapper{\r\n    background-color: #eee;\r\n    border-radius: 0.5em;\r\n    padding: 0.2em 0.5em;\r\n    opacity: 40%;\r\n}\r\n.card{\r\n    color: #666666;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": "ChatPanel_main__0J6so",
	"send": "ChatPanel_send__PZ--f",
	"title": "ChatPanel_title__1y9yp",
	"markdown_wrapper": "ChatPanel_markdown_wrapper__JWw8r",
	"card": "ChatPanel_card__A8gR-"
};
export default ___CSS_LOADER_EXPORT___;
