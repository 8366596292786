// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HotspotExamMain_placeHolder__uJD\\+Q{\r\n    min-height: 20vh;\r\n}\r\n.HotspotExamMain_basic_info__rea64{\r\n    color: #666666;\r\n    font-style: oblique;\r\n}\r\n.HotspotExamMain_quiz_title__SyDfL{\r\n    font-size: 1.2em;\r\n    margin-left: 2em;\r\n}\r\n.HotspotExamMain_timer__YsGY3{\r\n    color: #aaaaaa;\r\n}\r\n", "",{"version":3,"sources":["webpack://./src/components/Main/InfoRight/AnalysisPanel/main/HotspotExamMain/HotspotExamMain.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;AACA;IACI,cAAc;IACd,mBAAmB;AACvB;AACA;IACI,gBAAgB;IAChB,gBAAgB;AACpB;AACA;IACI,cAAc;AAClB","sourcesContent":[".placeHolder{\r\n    min-height: 20vh;\r\n}\r\n.basic_info{\r\n    color: #666666;\r\n    font-style: oblique;\r\n}\r\n.quiz_title{\r\n    font-size: 1.2em;\r\n    margin-left: 2em;\r\n}\r\n.timer{\r\n    color: #aaaaaa;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"placeHolder": "HotspotExamMain_placeHolder__uJD+Q",
	"basic_info": "HotspotExamMain_basic_info__rea64",
	"quiz_title": "HotspotExamMain_quiz_title__SyDfL",
	"timer": "HotspotExamMain_timer__YsGY3"
};
export default ___CSS_LOADER_EXPORT___;
