// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MindtraceHubResourcePlayer_container__DopxH{\r\n    padding-top: 0.8em;\r\n    min-height: 6vh;\r\n}\r\n.MindtraceHubResourcePlayer_option__uir2m{\r\n    display: flex;\r\n    align-items: center;\r\n}\r\n.MindtraceHubResourcePlayer_title__F2oQP{\r\n    font-size: 1.2em;\r\n    color: #666666;\r\n}\r\n.MindtraceHubResourcePlayer_description__vPBe4{\r\n    font-size: 0.9em;\r\n    color: #666666;\r\n}", "",{"version":3,"sources":["webpack://./src/components/Main/InfoRight/EnhancerPanel/resource/MindtraceHubResourcePlayer/MindtraceHubResourcePlayer.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,eAAe;AACnB;AACA;IACI,aAAa;IACb,mBAAmB;AACvB;AACA;IACI,gBAAgB;IAChB,cAAc;AAClB;AACA;IACI,gBAAgB;IAChB,cAAc;AAClB","sourcesContent":[".container{\r\n    padding-top: 0.8em;\r\n    min-height: 6vh;\r\n}\r\n.option{\r\n    display: flex;\r\n    align-items: center;\r\n}\r\n.title{\r\n    font-size: 1.2em;\r\n    color: #666666;\r\n}\r\n.description{\r\n    font-size: 0.9em;\r\n    color: #666666;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "MindtraceHubResourcePlayer_container__DopxH",
	"option": "MindtraceHubResourcePlayer_option__uir2m",
	"title": "MindtraceHubResourcePlayer_title__F2oQP",
	"description": "MindtraceHubResourcePlayer_description__vPBe4"
};
export default ___CSS_LOADER_EXPORT___;
