// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GuestSearch_container__EzvXq{\r\n    display: flex;\r\n    flex-direction: column;\r\n    justify-content: center;\r\n    align-items: center;\r\n}\r\n.GuestSearch_main__AV5O3{\r\n    display: flex;\r\n    flex-direction: column;\r\n    justify-content: center;\r\n    align-items: center;\r\n    width: 50%;\r\n    overflow-y: scroll;\r\n    height: 98%;\r\n\r\n}\r\n.GuestSearch_search__92dxo{\r\n    width: 100%;\r\n    max-height: 90%;\r\n}\r\n.GuestSearch_icon__y4lHW{\r\n    font-size: 2em;\r\n    font-weight: lighter;\r\n    font-style: oblique;\r\n}\r\n.GuestSearch_ipc_info__fBuDp{\r\n    position: absolute;\r\n    left: 1em;\r\n    bottom: 1em;\r\n    text-decoration: none;\r\n    color: #cccccc;\r\n    font-size: 0.8em;\r\n}", "",{"version":3,"sources":["webpack://./src/components/Search/GuestSearch.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;AACvB;AACA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,UAAU;IACV,kBAAkB;IAClB,WAAW;;AAEf;AACA;IACI,WAAW;IACX,eAAe;AACnB;AACA;IACI,cAAc;IACd,oBAAoB;IACpB,mBAAmB;AACvB;AACA;IACI,kBAAkB;IAClB,SAAS;IACT,WAAW;IACX,qBAAqB;IACrB,cAAc;IACd,gBAAgB;AACpB","sourcesContent":[".container{\r\n    display: flex;\r\n    flex-direction: column;\r\n    justify-content: center;\r\n    align-items: center;\r\n}\r\n.main{\r\n    display: flex;\r\n    flex-direction: column;\r\n    justify-content: center;\r\n    align-items: center;\r\n    width: 50%;\r\n    overflow-y: scroll;\r\n    height: 98%;\r\n\r\n}\r\n.search{\r\n    width: 100%;\r\n    max-height: 90%;\r\n}\r\n.icon{\r\n    font-size: 2em;\r\n    font-weight: lighter;\r\n    font-style: oblique;\r\n}\r\n.ipc_info{\r\n    position: absolute;\r\n    left: 1em;\r\n    bottom: 1em;\r\n    text-decoration: none;\r\n    color: #cccccc;\r\n    font-size: 0.8em;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "GuestSearch_container__EzvXq",
	"main": "GuestSearch_main__AV5O3",
	"search": "GuestSearch_search__92dxo",
	"icon": "GuestSearch_icon__y4lHW",
	"ipc_info": "GuestSearch_ipc_info__fBuDp"
};
export default ___CSS_LOADER_EXPORT___;
