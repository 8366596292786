// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MarkdownPlayer_container__JQYvC{\r\n    padding: 2vh 1vh 1vh;\r\n    min-height: 6vh;\r\n}\r\n.MarkdownPlayer_double_txt__afE9N{\r\n    display: flex;\r\n\r\n}", "",{"version":3,"sources":["webpack://./src/components/Main/InfoRight/EnhancerPanel/resource/MarkdownPlayer/MarkdownPlayer.module.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,eAAe;AACnB;AACA;IACI,aAAa;;AAEjB","sourcesContent":[".container{\r\n    padding: 2vh 1vh 1vh;\r\n    min-height: 6vh;\r\n}\r\n.double_txt{\r\n    display: flex;\r\n\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "MarkdownPlayer_container__JQYvC",
	"double_txt": "MarkdownPlayer_double_txt__afE9N"
};
export default ___CSS_LOADER_EXPORT___;
