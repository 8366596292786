// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AudioPlayer_container__Tdenw{\r\n    display: flex;\r\n    justify-content: center;\r\n}\r\n.AudioPlayer_container__Tdenw > *{\r\n    margin-right: 2em;\r\n}\r\n.AudioPlayer_upload__2qy5g{\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n}\r\n.AudioPlayer_title__DHOb2{\r\n    font-size: 1.2em;\r\n    color: #666666;\r\n}", "",{"version":3,"sources":["webpack://./src/components/Main/InfoRight/EnhancerPanel/resource/AudioPlayer/AudioPlayer.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;AAC3B;AACA;IACI,iBAAiB;AACrB;AACA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;AACA;IACI,gBAAgB;IAChB,cAAc;AAClB","sourcesContent":[".container{\r\n    display: flex;\r\n    justify-content: center;\r\n}\r\n.container > *{\r\n    margin-right: 2em;\r\n}\r\n.upload{\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n}\r\n.title{\r\n    font-size: 1.2em;\r\n    color: #666666;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "AudioPlayer_container__Tdenw",
	"upload": "AudioPlayer_upload__2qy5g",
	"title": "AudioPlayer_title__DHOb2"
};
export default ___CSS_LOADER_EXPORT___;
