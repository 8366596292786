// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HotspotConfigs_container__ptbiS{\r\n    min-height: 50vh;\r\n    margin-top: 2em;\r\n}\r\n\r\n.HotspotConfigs_config_container__tUaMo{\r\n    height: 100%;\r\n}\r\n\r\n.HotspotConfigs_config_container__tUaMo > *{\r\n    height: 100%;\r\n}\r\n.HotspotConfigs_info__BNOFW{\r\n    font-weight: lighter;\r\n    font-size: 1.1em;\r\n    font-style: oblique;\r\n}", "",{"version":3,"sources":["webpack://./src/components/Main/InfoRight/AnalysisPanel/ExamConfigs/HotspotConfigs/HotspotConfigs.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,YAAY;AAChB;AACA;IACI,oBAAoB;IACpB,gBAAgB;IAChB,mBAAmB;AACvB","sourcesContent":[".container{\r\n    min-height: 50vh;\r\n    margin-top: 2em;\r\n}\r\n\r\n.config_container{\r\n    height: 100%;\r\n}\r\n\r\n.config_container > *{\r\n    height: 100%;\r\n}\r\n.info{\r\n    font-weight: lighter;\r\n    font-size: 1.1em;\r\n    font-style: oblique;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "HotspotConfigs_container__ptbiS",
	"config_container": "HotspotConfigs_config_container__tUaMo",
	"info": "HotspotConfigs_info__BNOFW"
};
export default ___CSS_LOADER_EXPORT___;
