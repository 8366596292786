// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".StatisticsAnalysisCard_time__zOpn1{\r\n    font-size: 1.5em;\r\n    font-weight: lighter;\r\n    font-style: oblique;\r\n    color: #666666;\r\n    padding-left: 0.3em;\r\n}\r\n.StatisticsAnalysisCard_label__3mPmJ{\r\n    display: flex;\r\n}\r\n.StatisticsAnalysisCard_label__3mPmJ > *{\r\n    margin-right: 1em;\r\n}", "",{"version":3,"sources":["webpack://./src/components/Main/InfoRight/AnalysisPanel/analysis/StatisticsAnalysisCard/StatisticsAnalysisCard.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,oBAAoB;IACpB,mBAAmB;IACnB,cAAc;IACd,mBAAmB;AACvB;AACA;IACI,aAAa;AACjB;AACA;IACI,iBAAiB;AACrB","sourcesContent":[".time{\r\n    font-size: 1.5em;\r\n    font-weight: lighter;\r\n    font-style: oblique;\r\n    color: #666666;\r\n    padding-left: 0.3em;\r\n}\r\n.label{\r\n    display: flex;\r\n}\r\n.label > *{\r\n    margin-right: 1em;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"time": "StatisticsAnalysisCard_time__zOpn1",
	"label": "StatisticsAnalysisCard_label__3mPmJ"
};
export default ___CSS_LOADER_EXPORT___;
