// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EnhancerSearch_container__d9Fgi{\r\n    padding: 0.5em;\r\n    min-width: 20em;\r\n}\r\n.EnhancerSearch_container__d9Fgi > *{\r\n    padding-bottom: 0.3em;\r\n}\r\n.EnhancerSearch_enhancer_title__huDs2{\r\n    color: #666666;\r\n    font-weight: bold;\r\n    font-style: oblique;\r\n    margin-right: 1em;\r\n    cursor: pointer;\r\n}\r\n.EnhancerSearch_enhancer_title__huDs2:hover{\r\n    opacity: 50%;\r\n}", "",{"version":3,"sources":["webpack://./src/components/Main/InfoRight/RecordPanel/HistoryStudyRecord/StudyTraceTimeline/EnhancerSearch.module.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,eAAe;AACnB;AACA;IACI,qBAAqB;AACzB;AACA;IACI,cAAc;IACd,iBAAiB;IACjB,mBAAmB;IACnB,iBAAiB;IACjB,eAAe;AACnB;AACA;IACI,YAAY;AAChB","sourcesContent":[".container{\r\n    padding: 0.5em;\r\n    min-width: 20em;\r\n}\r\n.container > *{\r\n    padding-bottom: 0.3em;\r\n}\r\n.enhancer_title{\r\n    color: #666666;\r\n    font-weight: bold;\r\n    font-style: oblique;\r\n    margin-right: 1em;\r\n    cursor: pointer;\r\n}\r\n.enhancer_title:hover{\r\n    opacity: 50%;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "EnhancerSearch_container__d9Fgi",
	"enhancer_title": "EnhancerSearch_enhancer_title__huDs2"
};
export default ___CSS_LOADER_EXPORT___;
